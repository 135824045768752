<template>
    <div>
        <div v-html="Content[0].content" v-if="Content[0]"></div>

        <div class="hostname">
            <div class="hostfl">
                Host's Name：{{ form.host_first_name }}{{ form.host_last_name }}
            </div>
            <div class="hostfl">
                Email：{{ form.host_email }}
            </div>
            <div class="hostfl">
                phone：{{ form.host_phone }}
            </div>
            <div class="hostfl">
                Home Address：{{ form.home_address }}
            </div>
            <div class="hostfl">
                Date：{{ form.host_date_first }}
            </div>
            <div class="hostfl">
                <div class="en">Signature：</div>
                <img :src="`${form.signature_first}`" alt="">
            </div>
        </div>

        <div class="admin">Administration Only - Do Not Change</div>
        <div class="hostname">
            <div class="hostfl">
                Student Name：{{ form.student_first_name }}{{ form.student_last_name }}
            </div>
            <div class="hostfl">
                Fiscal Date (Move-in Date) ：{{ form.fiscal_date }}
            </div>
            <div class="hostfl">
                Monthly Homestay Fee：{{ form.monthly_homestay_fee }}
            </div>
        </div>

        <!-- <div class="width80"></div> -->
        <div v-html="Content[1].content" v-if="Content[1]"></div>
        <div class="english">"I hereby agree to the terms and conditions set forth above."</div>
        <div class="hostname">
            <div class="hostfl">
                Host's Name：{{ form.host_first_name }}{{ form.host_last_name }}
            </div>
            <div class="hostfl">
                Date：{{ form.host_date_second }}
            </div>
            <div class="hostfl">
                <div class="en">Signature：</div>
                <img :src="`${form.signature_second}`" alt="">
            </div>
        </div>
        <div v-html="Content[2].content" v-if="Content[2]"></div>
        <div class="english">"I hereby agree to the terms and conditions set forth above."</div>
        <div class="hostname">
            <div class="hostfl">
                Host's Name：{{ form.student_first_name }}{{ form.student_last_name }}
            </div>
            <div class="hostfl">
                Date：{{ form.host_date_third }}
            </div>
            <div class="hostfl">
                <div class="en">Signature：</div>
                <img :src="`${form.signature_third}`" alt="">
            </div>
        </div>
        <div class="width800"></div>
        <div v-html="Content[3].content" v-if="Content[3]"></div>
        <div class="english">“I hereby acknowledge that I have read and understood the above information. I will, to the
            best of my ability, follow procedures that have been outlined above.”</div>
        <div class="hostname">
            <div class="hostfl">
                Host's Name：{{ form.host_first_name }}{{ form.host_last_name }}
            </div>
            <div class="hostfl">
                Date：{{ form.host_date_fourth }}
            </div>
            <div class="hostfl">
                <div class="en">Signature：</div>
                <img :src="`${form.signature_fourth}`" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { Operator, DataHX } from '@/api/home.js';
export default {
    data() {
        return {
            Content: "",
            form: {},
        }
    },
    mounted() {
        this.HandleHome().then(res => {
            this.HandleData()
        });

    },
    methods: {
        async HandleData() {
            var id = this.$route.query.id;
            if (id) {
              await DataHX({ id: id }).then(res => {
                    if (res.code == 200) {
                        // alert(document.getElementsByName('other_choice')[0].value);
                        console.log(res.data);
                        this.form = res.data;
                        document.getElementsByClassName('top-rides')[0].innerHTML = res.data.school_rides;
                        document.getElementsByClassName('airport')[0].innerHTML = res.data.designated_airport;
                        document.getElementsByClassName('hosting')[0].innerHTML = res.data.type_of_hosting;
                        document.getElementsByClassName('deposit-fee')[0].innerHTML = res.data.deposit_fee;
                        document.getElementById('name_stu').innerHTML = `${res.data.host_first_name}-${res.data.host_last_name}`
                        // 如果选项不等于Choice 执行 把选择的内容进行回显 ，如若不是则把子级别内容进行清空
                        if (res.data.school_rides == "Other Choice") {
                            document.getElementsByClassName('other-choice')[0].innerHTML = res.data.other_choice
                        } else {
                            document.getElementsByClassName('other-choice')[0].innerHTML = ""
                        }
                    }
                });
            }
        },
        async HandleHome() {
            await Operator().then(res => {
                if (res.code == 200) {
                    this.Content = res.data;

                }
            });
        },
        HandleClick() {

        },
    },
}
</script>

<style lang="scss" scoped>
.admin {
    color: #333;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
}

.english {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 30px auto 60px 0;
}

.hostname {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 20px auto 20px auto;

    .hostfl {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        color: rgb(32, 33, 36);
        font-family: 微软雅黑;
        font-size: 12px;

        img {
            width: 400px;
            height: 150px;
        }
    }
}

::v-deep p {
    margin-top: 10px;
}

::v-deep .top-10 {
    margin: 10px auto 0 auto;
}

::v-deep .top-10 .weight-14 {
    margin: 0 0 10px 0;
}

::v-deep p img {
    width: 100% !important;
    height: auto !important;
}

::v-deep li span {
    // font-size: 14px !important;
    margin-top: 6px;
}

.width60 {
    width: 100%;
    height: 60px;
}

.width800 {
    width: 100%;
    height: 800px;
}

.width150 {
    width: 100%;
    height: 150px;
}

.width350 {
    width: 100%;
    height: 350px;
}
</style>